export const footerPhrases = [
  'Welcome to Paradise',
  'Your Business is Greatly Appreciated',
  'Have a Very Pleasant Day',
  'Premium Service on all Font Deliveries!',
  'Don’t Worry, it’s OK',
  'Born to Bézier',
  'Our Fonts Are Your Fonts',
  'Buy 1, Buy All',
  'Our Way or the Bézier',
  'In Béziers We Trust',
  'Infinite Blessings!',
  'Buy Fonts and Live Forever',
  'Your Satisfaction is our Priority',
  'The Shop & The Service',
  'Young or Old, We Treat You Like Solid Gold',
  'The Local Flagship Store',
  'Open 24 / 7 / 365',
  'Choose, Buy and Download',
  'Future Investments',
  'Autonomous Collective',
  'Independent Together',
  'Cultural Instructions',
  'Open From: 00:01 — 23:59',
  'Why Rush? Stay and Browse!',
  'Open Every Night',
  'Fonts, Fonts, Fonts! It’s OK to Repeat',
  'Hi! Hey! Hello!',
  'Buy Low, Sell High!',
  'General Public Welcome',
  'Hours of Fun',
  "A Message That's Familiar!",
  'United Kingdom, Europe, and the World',
  'Open Until Further Notice',
  'I Love the Typeface xoxo',
  'Fonts. Now Available Very Very Fast',
  'Art This Way →',
  'Serves You Right',
  'Does it Come in Black?',
  'You’re in Safe Hands',
  'This Website is from the Internet — Check that it is Genuine',
  'Serves Not Only Fonts, But Dreams',
  'World Wide Quality',
  'Let’s Do This!',
  'Free Collection & Delivery',
  'Your Trusted Partners in Typography',
  'You Will Be Delighted!',
  'Special Presentation',
  'No Freebies Here!',
  'Direct from the manufacturer!',
  'Fixed & Fair Prices!',
  'If We Don’t Have It, You Don’t Need It',
  'Kaizen! Kaizen! Kaizen!',
  'Extra Quality',
  'Delivery 120% to Patrons',
  'Necessarily Completely Necessary',
  'The know-how of knowing how to know',
  'Who wants to buy the past? Buy the future'
];
