import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

import Page from './Page';

// Resolved Storyblok Component to local Components
// sorted a-z
const Components = {
  Accordion: dynamic(() => import('./AccordionWrapper')),
  AddToCartButtons: dynamic(() => import('./AddToCartButtonsWrapper')),
  AccordionItem: dynamic(() => import('./AccordionItemWrapper')),
  Button: dynamic(() => import('./ButtonWrapper')),
  Card: dynamic(() => import('./CardWrapper')),
  Carousel: dynamic(() => import('./CarouselWrapper')),
  CodeBlock: dynamic(() => import('./CodeBlockWrapper')),
  Column: dynamic(() => import('./ColWrapper')),
  CustomFont: dynamic(() => import('./CustomFontWrapper')),
  CustomCode: dynamic(() => import('./CustomCodeWrapper')),
  DescriptionList: dynamic(() => import('./DescriptionListWrapper')),
  Grid: dynamic(() => import('./GridWrapper')),
  GlobalBlock: dynamic(() => import('./GlobalBlockWrapper')),
  FontLoader: dynamic(() => import('./FontLoaderWrapper')),
  Heading: dynamic(() => import('./HeadingWrapper')),
  Hero: dynamic(() => import('./HeroWrapper')),
  HeroCarousel: dynamic(() => import('./HeroCarouselWrapper')),
  HorizontalLine: dynamic(() => import('./HorizontalLineWrapper')),
  HoverImage: dynamic(() => import('./HoverImageWrapper')),
  HubspotFrame: dynamic(() => import('./EmbeddedHubSpotWrapper')),
  Icon: dynamic(() => import('./IconWrapper')),
  Image: dynamic(() => import('./ImageWrapper')),
  ImageWithText: dynamic(() => import('./ImageWithText')),
  Modal: dynamic(() => import('./ModalWrapper')),
  OpeningTimes: dynamic(() => import('./OpeningTimesWrapper')),
  OpenType: dynamic(() => import('./OpenTypeFeatureWrapper')),
  Panel: dynamic(() => import('./PanelWrapper')),
  page: Page,
  Products: dynamic(() => import('./ProductsWrapper')),
  Spacer: dynamic(() => import('./SpacerWrapper')),
  Table: dynamic(() => import('./TableWrapper')),
  Tabs: dynamic(() => import('./TabWrapper')),
  Tag: dynamic(() => import('./TagWrapper')),
  Tags: dynamic(() => import('./TagsWrapper')),
  Text: dynamic(() => import('./TextWrapper')),
  Theme: dynamic(() => import('./ThemeWrapper')),
  Timeline: dynamic(() => import('./TimelineWrapper')),
  TypefaceHero: dynamic(() => import('./TypefaceHeroWrapper')),
  TypefaceCards: dynamic(() => import('./TypefaceCardsWrapper')),
  VariableFont: dynamic(() => import('./VariableFontWrapper')),
  Video: dynamic(() => import('./VideoWrapper')),
  FaqSearch: dynamic(() => import('./FaqSearchWrapper')),
  FaqItems: dynamic(() => import('./FaqItemsWrapper')),
  FaqTopics: dynamic(() => import('./FaqTopicsWrapper')),
  SubscriptionForm: dynamic(() => import('./SubscriptionFormWrapper')),
  SupportHero: dynamic(() => import('./SupportHeroWrapper')),
  VideoHero: dynamic(() => import('./VideoHeroWrapper')),
  VideoHeroControls: dynamic(() => import('./VideoHeroControlsWrapper')),
  SiteTicker: dynamic(() => import('./SbSiteTicker')),
  TagsV2: dynamic(() => import('./TagsV2Wrapper'))
};

interface IProps {
  blok: any;
  styles?: string;
}

const DynamicComponent = ({ blok, styles }: IProps) => {
  const { isPreview } = useRouter();
  // check if component is defined above
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component];

    // wrap with SbEditable for visual editing
    return isPreview ? (
      <SbEditable content={blok}>
        <Component blok={blok} styles={styles} />
      </SbEditable>
    ) : (
      <Component blok={blok} styles={styles} />
    );
  }

  // fallback if the component doesn't exist
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

DynamicComponent.propTypes = {
  blok: PropTypes.object,
  styles: PropTypes.object || PropTypes.string
};

export default DynamicComponent;
