import NextLink from 'next/link';

type Props = {
  /**
   * Link url
   **/
  href: string;
  /**
   * Link label
   **/
  label: string;
  /**
   * Link className
   **/
  className?: string;
  /**
   * Link selected locale
   **/
  locale?: string;
};

const Link: React.FC<Props> = ({ href, label, className, locale }) => {
  //check if url is external link
  let re = new RegExp('^(http|https)://', 'i');
  let match = re.test(href);

  return !match ? (
    <NextLink href={href} locale={locale}>
      <a className={className}>{label}</a>
    </NextLink>
  ) : (
    <a href={href} className={className} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  );
};

export default Link;
