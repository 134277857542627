import React from 'react';

import DynamicComponent from '@/storyblok/components/DynamicComponent';

const SiteTickerWrapper = ({ storyContent }) => {
  // Strip out the Text components from globalHeader story
  const headerTapeTickerText = storyContent?.globalHeader?.content?.content.filter(
    (blok) => blok.component === 'SiteTicker'
  );

  if (!headerTapeTickerText || headerTapeTickerText.length < 1) {
    return null;
  }

  // @todo - style as required
  return (
    <>
      {headerTapeTickerText.map((headerTapeTickerTextContent) => (
        <React.Fragment key={headerTapeTickerTextContent._uid}>
          <DynamicComponent
            key={headerTapeTickerTextContent._uid}
            blok={headerTapeTickerTextContent}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default SiteTickerWrapper;
