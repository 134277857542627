import Link from 'next/link';

import styles from './Footer.module.scss';

const FooterLinks = ({ links }) => {
  if (!links || links.length < 1) return null;

  return (
    <ul className={styles.footerLinksContainer}>
      {links.map(({ slug, name }) => {
        return (
          <li key={slug}>
            <Link href={`/${slug}`}>
              <a className={[styles.footerLink, 'u-h6'].join(' ')}>{name}</a>
            </Link>
          </li>
        );
      })}
      <li></li>
    </ul>
  );
};

export default FooterLinks;
