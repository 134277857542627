import FocusTrap from 'focus-trap-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { INavigation } from 'types/common';

import { SiteTickerWrapper } from '@/components/cms/SiteTicker';
import Breadcrumbs from '@/components/global/Breadcrumbs';
import Button from '@/components/global/Button';
import { Column, Grid } from '@/components/global/Grid';
import Icon from '@/components/global/Icon';
import Menu from '@/components/global/Menu';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import { useHeaderTheme } from '@/components/providers/HeaderThemeProvider';
import checkoutDataLayer from '@/lib/datalayer/checkout';
import { useScreenSize } from '@/lib/hooks/mediaQuery';
import { useUser } from '@/lib/hooks/useUser';
import { usePaths } from '@/lib/paths';
import { CheckoutLineDetailsFragment } from '@/saleor/api';

import { DEFAULT_ARROW_ICON_SIZES } from '../Icon/defaultIconSizes';
import styles from './Header.module.scss';
import { LogoLabel } from './partials';

interface IProps extends INavigation {
  headerTheme?: string;
  hasBorder?: boolean;
  pageName?: string;
  showNavigation?: boolean;
  isDDLTransparent?: boolean;
  onTypefacePage?: boolean;
  renderParentOnly?: boolean;
  isMaintenance?: boolean;
  blok?: any;
}

const Header: React.FC<IProps> = ({
  navigation,
  headerTheme,
  hasBorder,
  pageName,
  showNavigation = true,
  onTypefacePage,
  isMaintenance = false,
  renderParentOnly = false,
  blok
}) => {
  const headerThemeSet = headerTheme || 'default';
  const headerRef = useRef();
  const paths = usePaths();
  const router = useRouter();
  const screenSize = useScreenSize();

  const topLevelNavLinks = navigation?.headerLinks?.[0]?.filter(
    (navItem) => navItem.parentId === 0
  );

  const { checkout } = useCheckout();
  const { headerState, setMenuOpen, headerClasses, setHeaderTheme } = useHeaderTheme();

  const menuIsOpen = headerState.menuOpen;
  const headerIsFixed = headerState.fixed;
  const toggleMenu = () => {
    setMenuOpen(!menuIsOpen);
  };

  const { authenticated } = useUser();

  useEffect(() => {
    const setMenuAsOpen = () => {
      setMenuOpen(false);
    };

    router.events.on('routeChangeStart', setMenuAsOpen);

    return () => {
      router.events.off('routeChangeStart', setMenuAsOpen);
    };
  }, []);

  // Sync page header theme with state
  useEffect(() => {
    setHeaderTheme(headerThemeSet);
  }, []);

  useEffect(() => {
    setHeaderTheme(headerThemeSet);
  }, [headerThemeSet]);

  const counter =
    checkout?.lines?.reduce(
      (amount: number, line?: CheckoutLineDetailsFragment | null) =>
        line ? amount + line.quantity : amount,
      0
    ) || 0;

  const focusTrapOptions = {
    allowOutsideClick: true
  };

  const onHome =
    router.asPath === '/' || router.pathname === '/404' || router.pathname === '/maintenance';

  return (
    <div>
      <a className="skip-to-content-link" href="#main">
        Skip to content
      </a>
      <header ref={headerRef} className={headerClasses}>
        <div className={styles.header__content}>
          <SiteTickerWrapper storyContent={blok} />
        </div>
        <Grid rowAlign="center">
          <Column sm={8} md={9} lg={onHome ? 9 : 1}>
            <div
              className={[styles.logoNav, menuIsOpen ? styles.logoNav_menuIsOpen : ''].join(' ')}
            >
              <Link href={isMaintenance ? '/maintenance' : '/'}>
                <a className={styles.logo} title="Colophon">
                  <Icon iconName="logo-small" color="var(--header-color)" width="18" height="23" />
                  {onHome ? (
                    <>
                      <div className={`${styles.logoLabel} u-h5`}>
                        <LogoLabel />
                      </div>
                    </>
                  ) : null}
                </a>
              </Link>
              {!onHome ? (
                <div className={styles.breadcrumbArrow}>
                  <Icon
                    iconName="arrow-right"
                    color="var(--header-color)"
                    {...DEFAULT_ARROW_ICON_SIZES}
                  />
                </div>
              ) : null}
              <div className={styles.breadcrumbMobile}>
                {!onHome && (
                  <Breadcrumbs pageName={pageName} onTypefacePage={onTypefacePage} hideBuyNow />
                )}
              </div>
            </div>
          </Column>

          {!onHome && (
            <Column sm={0} md={0} lg={8}>
              <div className={styles.breadcrumbDesktop}>
                <Breadcrumbs pageName={pageName} onTypefacePage={onTypefacePage} />
              </div>
            </Column>
          )}

          {showNavigation && (
            <Column sm={4} md={3} lg={3}>
              <nav role="navigation" aria-label="Site Menu">
                <div className={styles.navtopIcons}>
                  <div className={styles.topIcon}>
                    {!authenticated && (
                      <Button
                        href={paths.account.login.$url()}
                        variant="ghost"
                        title={'Login to Account'}
                      >
                        <Icon iconName="account" color="var(--header-icon-color)" />
                      </Button>
                    )}
                    {authenticated && (
                      <Button href={paths.account.$url()} variant="ghost" title={'View Account'}>
                        <Icon iconName="account" color="var(--header-icon-color)" />
                      </Button>
                    )}
                  </div>
                  <div className={styles.topIcon}>
                    <Button
                      // add 'view_cart' to the dataLayer
                      callBack={() => {
                        checkoutDataLayer.viewCart(checkout?.lines || []);
                      }}
                      href={paths.cart.$url()}
                      variant="ghost"
                      title={'Open Cart'}
                    >
                      <Icon iconName="cart" color="var(--header-icon-color)" />
                      <span className={styles.basketCount}>{counter}</span>
                    </Button>
                  </div>
                  <FocusTrap active={menuIsOpen} focusTrapOptions={focusTrapOptions}>
                    <div className={styles.topIcon}>
                      <Button
                        callBack={toggleMenu}
                        variant="ghost"
                        title={`${menuIsOpen ? 'Close Menu' : 'Open Menu'}`}
                        aria-expanded={menuIsOpen}
                      >
                        <Icon
                          iconName={menuIsOpen ? 'close' : 'burger'}
                          color="var(--header-icon-color)"
                        />
                      </Button>
                      {screenSize !== 'Mobile' && (
                        <React.Suspense fallback={null}>
                          <Menu menuIsOpen={menuIsOpen} navigation={topLevelNavLinks} />
                        </React.Suspense>
                      )}
                    </div>
                  </FocusTrap>
                </div>
              </nav>
            </Column>
          )}
          {hasBorder && (
            <div className={styles.headerBorder}>
              <Grid>
                <Column sm={12} md={12} lg={12}>
                  <hr />
                </Column>
              </Grid>
            </div>
          )}
        </Grid>
      </header>
      {screenSize === 'Mobile' && (
        <React.Suspense fallback={null}>
          <Menu menuIsOpen={menuIsOpen} navigation={topLevelNavLinks} />
        </React.Suspense>
      )}
    </div>
  );
};

export default Header;
