import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';

import { sendEventGA } from '@/lib/ga';
import { inputFieldValidation } from '@/lib/util';

import Button from '../Button';
import Checkbox from '../Checkbox';
import Link from '../Link';
import styles from './Footer.module.scss';

// Consider switching to react-icons!

type SocialProps = {
  facebook?: string;
  instagram?: string;
  twitter?: string;
  pinterest?: string;
  linkedIn?: string;
  youtube?: string;
  subscriptionMessage?: string;
  setSubscriptionMessage?: (message: string) => void;
};

const FooterSocial: React.FC<SocialProps> = ({
  instagram,
  twitter,
  pinterest,
  setSubscriptionMessage
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    resetField,
    getValues,
    watch
  } = useForm({ mode: 'onChange' });

  watch('email');

  const email = getValues('email');

  watch('email');

  const handleSubscribe = handleSubmit(async (data) => {
    const { email } = data;
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_EMAIL_SUBSCRIPTION_URL}${email}`, {
        method: 'POST'
      });
      if (response.status === 200) {
        setSubscriptionMessage('Thank you for subscribing to our newsletter');
        sendEventGA('send', 'pageview', {
          page: '/newsletter-signup/'
        });
      } else {
        setSubscriptionMessage('Fail!');
      }
    } catch (error) {
      setSubscriptionMessage('Fail!');
    }
    resetField('email');
    resetField('acceptPolicy');
  });

  return (
    <>
      <ul className={clsx(styles.socialContainer, 'u-h6')}>
        {instagram && (
          <li>
            <a
              className={styles.social}
              href={instagram}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
            >
              Instagram
            </a>
          </li>
        )}
        {twitter && (
          <li className={styles.lastLi}>
            <a
              className={styles.social}
              href={twitter}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              Twitter
            </a>
          </li>
        )}
        {pinterest && (
          <li className={styles.lastLi}>
            <a
              className={styles.social}
              href={pinterest}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              Pinterest
            </a>
          </li>
        )}
      </ul>
      <div className={clsx(styles.newsletter, 'u-h6')}>
        <p className={clsx(styles.social, styles.newsletterText)}>Newsletter</p>
        <form className={styles.newsletterForm} onSubmit={handleSubscribe}>
          <div className={styles.newsletterFormContainer}>
            <input
              type="email"
              aria-label="email"
              className={styles.newsletterInput}
              placeholder="mail@website.com"
              {...register('email', {
                required: true,
                ...inputFieldValidation.emailFormat
              })}
            />
            <Button className={styles.newsletterSubmit} disabled={!isValid}>
              Subscribe
            </Button>
          </div>
          {email && (
            <div className={styles.acceptPolicy}>
              <Checkbox
                name="acceptPolicy"
                value="Yes"
                id="acceptPolicy"
                label="Accept Colophon Foundry Privacy Policy."
                subLabel=""
                isSmall
                hideLabel
                {...register('acceptPolicy', { required: true })}
              />
              <span className={styles.acceptPolicy__text}>
                I&apos;ve read and accept the{' '}
                <Link
                  href="/privacy-policy"
                  label="Colophon Foundry Privacy Policy."
                  className={styles.acceptPolicy__link}
                />
              </span>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default FooterSocial;
