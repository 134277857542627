import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import Button from '@/components/global/Button';
import Icon from '@/components/global/Icon';
import { useTypefaceMenu } from '@/components/providers/TypefaceMenuProvider';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';

import Dropdown from '../Dropdown';
import { DEFAULT_ARROW_ICON_SIZES } from '../Icon/defaultIconSizes';
import styles from './Breadbrumbs.module.scss';

interface IProps {
  pageName?: string;
  onTypefacePage?: boolean;
  hideBuyNow?: boolean;
  renderParentOnly?: boolean;
}

const BreadbrumbDropdownMenu = ({ overviewLink, pageSlug }) => {
  const { items } = useTypefaceMenu();
  let activePage = items.find((item) => item.href === pageSlug);

  if (activePage) {
    activePage = { url: activePage.href, name: activePage.text };
  } else {
    activePage = overviewLink;
  }

  const dropdownItems = items
    .filter((item) => item.href !== activePage.url)
    .map((item) => {
      return {
        name: item.text,
        url: item.href
      };
    });

  // Add overview link if it's not the active page
  if (pageSlug !== overviewLink.url && !dropdownItems.includes(overviewLink)) {
    dropdownItems.unshift(overviewLink);
  }

  return <Dropdown fullWidth items={dropdownItems} defaultValue={activePage} />;
};

const BreadcrumbArrow = () => (
  <>
    <div className={styles.arrowDesktop}>
      <Icon
        key="breadcrumb-arrow-desktop"
        iconName="arrow-right"
        color="var(--header-color)"
        {...DEFAULT_ARROW_ICON_SIZES}
      />
    </div>
    <div className={styles.arrowMobile}>
      <Icon
        key="breadcrumb-arrow-mobile"
        iconName="arrow-right"
        color="var(--header-color)"
        {...DEFAULT_ARROW_ICON_SIZES}
      />
    </div>
  </>
);

const Breadcrumbs: React.FC<IProps> = ({
  pageName,
  onTypefacePage,
  hideBuyNow = false,
  renderParentOnly = false
}) => {
  const { setIsOpen: setConfiguratorVisible, state: typefaceState } = useTypefaceAddToCart();
  const { isOpen: configuratorVisible } = typefaceState;
  const [windowSlug, setWindowSlug] = useState('');

  const router = useRouter();

  const typefaceRoot = router.asPath.split('/').slice(0, 3).join('/');
  const overviewLink = { url: typefaceRoot, name: 'Overview' };

  useEffect(() => {
    const pageSlug = window.location.pathname;
    setWindowSlug(pageSlug);
  }, []);

  const breadcrumbs = useMemo(
    function generateBreadcrumbs() {
      const asPathWithoutQuery = router.asPath.split('?')[0];
      const asPathNestedRoutes = asPathWithoutQuery.split('/').filter((v) => v.length > 0);

      const crumbsListData = (crumbs) =>
        crumbs.map((subpath, idx) => {
          const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');
          // the pageName override is only for the page the user is browsing
          // if we need in future to manually control page names for all the breadcrumbs
          // we'll need a much better solution
          const pageSlug = windowSlug.split('/');

          let pageDisplayName =
            subpath === pageSlug?.[pageSlug.length - 1] // get the current page slug
              ? pageName || subpath.replace('-', ' ')
              : subpath.replaceAll('-', ' ');

          // Always use pageName for parent only crumbs
          if (renderParentOnly) {
            pageDisplayName = pageName;
          }

          return { href, name: pageDisplayName };
        });

      const topLevelCrumblist = crumbsListData(asPathNestedRoutes.slice(0, 2));

      return topLevelCrumblist;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath, windowSlug]
  );

  return (
    <div className={styles.breadcrumbs}>
      <ul>
        {!renderParentOnly ? (
          breadcrumbs.map((crumb, index) => (
            <li key={crumb.href}>
              <span className={styles.breadcrumbs__link}>
                <Link href={crumb.href}>
                  <a className={clsx('u-h6', styles.name)}>
                    <span>{crumb.name}</span>
                  </a>
                </Link>
                {index < breadcrumbs.length - 1 && <BreadcrumbArrow />}
              </span>
            </li>
          ))
        ) : (
          <li key={breadcrumbs[0].href}>
            <span className={styles.breadcrumbs__link}>
              <Link href={breadcrumbs[0].href}>
                <a className={clsx('u-h6', styles.name)}>
                  <span>{breadcrumbs[0].name}</span>
                </a>
              </Link>
            </span>
          </li>
        )}
      </ul>
      {!hideBuyNow && onTypefacePage && (
        <div className={styles.dropdownMenu}>
          <BreadcrumbArrow />
          <BreadbrumbDropdownMenu overviewLink={overviewLink} pageSlug={windowSlug} />
          <Button
            size="l"
            callBack={() => {
              setConfiguratorVisible(!configuratorVisible);
            }}
            data-cy="breadcrumb-buy-now"
          >
            Buy Now
          </Button>
        </div>
      )}
    </div>
  );
};

export default Breadcrumbs;
