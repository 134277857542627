import React, { useEffect, useRef, useState } from 'react';

import { isLightColor } from '@/lib/isLightColor';

type Props = {
  children?: any;
  variant?: string;
  as?: React.ElementType;
  applyBackground?: boolean;
  colorBackground?: string;
  colorPrimary?: string;
};
const Theme: React.FC<Props> = ({
  children,
  variant,
  as,
  applyBackground,
  colorBackground,
  colorPrimary
}) => {
  const themeRef = useRef(null);
  const [isLightBackground, setIsLightBackground] = useState(false);
  const Tag = as || 'div';
  const style: React.CSSProperties = {
    ...(applyBackground && {
      backgroundColor: 'var(--color-background)'
    })
  };
  if (colorBackground) style['--color-background'] = colorBackground;
  if (colorPrimary) style['--color-primary'] = colorPrimary;
  if (!isLightBackground) style['--theme-override-color'] = 'var(--white)';

  useEffect(() => {
    if (themeRef.current) {
      var styles = window.getComputedStyle(themeRef.current);
      var color = styles.getPropertyValue('--color-background'); // get current color background
      setIsLightBackground(isLightColor(color));
    }
  }, [themeRef, colorBackground]);

  return (
    <Tag style={style} className={`th-${variant}`} ref={themeRef}>
      {children}
    </Tag>
  );
};

export default Theme;
