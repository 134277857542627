import React from 'react';
import Marquee from 'react-fast-marquee';

import { useSiteTicker } from '@/components/providers/SiteTickerProvider';

import Theme from '../Theme';
import styles from './SiteTicker.module.scss';

type backgroundColor = {
  color: string;
};

interface IProps {
  foregroundColor: backgroundColor;
  backgroundColor: backgroundColor;
}

const SiteTicker: React.FC<IProps> = ({ children, foregroundColor, backgroundColor }) => {
  const { showTicker, hideTicker } = useSiteTicker();

  const tickerHidden = showTicker === 'false';
  if (tickerHidden) return null;

  return (
    <>
      <Theme colorBackground={backgroundColor.color} colorPrimary={foregroundColor.color}>
        <div className={styles.siteticker}>
          <div className={styles.siteticker__inner}>
            <div className={styles.siteticker__innerColumn}>
              <Marquee gradient={false} speed={40} pauseOnHover>
                {children}
              </Marquee>
              <button type="button" onClick={() => hideTicker()} className={styles.tickerClose}>
                ❌
              </button>
            </div>
          </div>
        </div>
      </Theme>
    </>
  );
};

export default SiteTicker;
