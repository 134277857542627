const getNavigations = (navigation) => {
  const { links = [] } = navigation;
  return links
    .map((link) => {
      const haveData = link.Link.cached_url && link.LinkName;
      return haveData
        ? {
            slug: link.Link.cached_url,
            name: link.LinkName,
            parentId: 0
          }
        : null;
    })
    .filter((item) => item !== null && item.parentId === 0);
};

export function getStoryBlokNavLinks(story) {
  if (!story) {
    return {
      headerLinks: [],
      footerLinks: []
    };
  }

  const { content } = story;
  const { globalHeader, globalFooter } = content;

  const headerGlobal = globalHeader?.content?.content || [];
  const footerGlobal = globalFooter?.content?.content || [];
  const headerNav = headerGlobal.filter((blok) => blok.component === 'NavigationWrapper');
  const footerNav = footerGlobal.filter((blok) => blok.component === 'NavigationWrapper');
  const headerLinks = headerNav.map((nav) => getNavigations(nav));
  const footerLinks = footerNav.map((nav) => getNavigations(nav));
  return {
    headerLinks,
    footerLinks
  };
}
