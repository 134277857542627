//* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-cycle
import DynamicComponent from './DynamicComponent';

const Page = ({ blok }) => (
  <>
    {blok.body
      ? // eslint-disable-next-line no-shadow
        blok.body.map((blok) => (
          // eslint-disable-next-line no-underscore-dangle
          <DynamicComponent blok={blok} key={blok._uid} />
        ))
      : null}
  </>
);

export default Page;
