import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { Column, Grid } from '@/components/global/Grid';
import {
  SOCIAL_FACEBOOK_ID,
  SOCIAL_INSTAGRAM_ID,
  SOCIAL_PINTEREST_ID,
  SOCIAL_TWITTER_ID
} from '@/lib/constants';
import { useRandomNumber } from '@/lib/hooks/randomNumber';

import Link from '../Link';
import Toast from '../Toast';
import styles from './Footer.module.scss';
import FooterLinks from './FooterLinks';
import FooterSocial from './FooterSocial';
import { footerPhrases } from './phrases';

const footerDate = new Date().getFullYear();

const Footer = ({ navigation, theme = '' }) => {
  const [subscriptionMessage, setSubscriptionMessage] = useState('');
  const [footerPhrase, setFooterPhrase] = useState('');
  const randomMessageNumber = useRandomNumber(footerPhrases.length);

  useEffect(() => {
    const randomMessage = () => {
      var i = randomMessageNumber;
      return footerPhrases[i];
    };

    setFooterPhrase(randomMessage());
  }, []);

  return (
    <div className={clsx(theme && styles[`footer-${theme}`], !theme && styles['footer-normal'])}>
      <Grid as="footer" className={styles.container}>
        <Column sm={12} md={12} lg={12}>
          <hr />
        </Column>
        <Column sm={12} md={6} lg={6}>
          <nav aria-label="Footer navigation">
            {navigation.map((navArray, i) => (
              <FooterLinks key={i} links={navArray} />
            ))}
          </nav>
        </Column>
        <Column sm={12} md={6} lg={6}>
          <FooterSocial
            facebook={SOCIAL_FACEBOOK_ID}
            instagram={SOCIAL_INSTAGRAM_ID}
            twitter={SOCIAL_TWITTER_ID}
            pinterest={SOCIAL_PINTEREST_ID}
            subscriptionMessage={subscriptionMessage}
            setSubscriptionMessage={setSubscriptionMessage}
          />
        </Column>
        <Column sm={12} md={12} lg={12}>
          <hr />
          <div className={styles.footerSupport}>
            <p className="u-h5">
              We are here for you <br />
              <a className={styles.mailTo} href="https://www.monotype.com/colophon-speak-sales">
                Contact Us
              </a>
            </p>
          </div>
          <hr className={styles.hrSmall} />
        </Column>

        <Column sm={12} md={0} lg={0} xl={0}>
          <div className={styles.mobileTagline}>
            <p>{footerPhrase}</p>
          </div>
        </Column>
        <Column sm={12} md={12} lg={12}>
          <div className={styles.subFooter}>
            <p className={styles.subFooterCopy}>&copy; {`${footerDate} Monotype Imaging Inc.`}</p>
            <p className={styles.subFooterTagline}>{footerPhrase}</p>
            <p className={styles.subFooterNav}>
              <Link
                className={styles.subFooterTerms}
                href="https://www.monotype.com/legal/privacy-policy"
                label="Privacy Policy"
              />
              <Link
                className={styles.subFooterTerms}
                href="https://www.monotype.com/legal/terms-use"
                label="Terms of Service"
              />
            </p>
          </div>
        </Column>
        {subscriptionMessage && (
          <Toast
            message={subscriptionMessage}
            onClose={() => setSubscriptionMessage('')}
            className={styles.subscriptionToast}
            autoClose
            autoCloseTime={5000}
          />
        )}
      </Grid>
    </div>
  );
};

export default Footer;
