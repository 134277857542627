import { useApolloClient } from '@apollo/client';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Button from '@/components/global/Button';
import panelStyles from '@/components/global/Header/HeaderPanel.module.scss';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import { useHeaderTheme } from '@/components/providers/HeaderThemeProvider';
import { useSaleorAuthContext } from '@/lib/auth';
import { useUser } from '@/lib/hooks/useUser';
import { usePaths } from '@/lib/paths';

import styles from './Menu.module.scss';

interface MenuProps {
  menuIsOpen?: boolean;
  navigation?: any;
}

const Menu: React.FC<MenuProps> = ({ menuIsOpen, navigation }) => {
  const { authenticated } = useUser();
  const { signOut } = useSaleorAuthContext();
  const { resetCheckoutToken } = useCheckout();
  const client = useApolloClient();
  const router = useRouter();
  const paths = usePaths();
  const { setMenuOpen } = useHeaderTheme();

  const Navigation = () => (
    <ul className={styles.menu}>
      {navigation &&
        navigation.map((link, i) => (
          <li key={i} className={styles.topMenu}>
            <Link href={link.slug ? `/${link.slug}` : '#'} as={link.slug ? `/${link.slug}` : '#'}>
              <a title={link.name}>{link.name}</a>
            </Link>
          </li>
        ))}
    </ul>
  );

  const onLogout = async () => {
    await signOut();
    await resetCheckoutToken();
    await client.resetStore();
    setMenuOpen(false);
    router.push({
      pathname: '/',
      query: {
        logout: 1
      }
    });
  };

  return (
    <>
      {menuIsOpen && (
        <div className={`${panelStyles.headerPanel} ${styles.mobileMenu} u-desktop-hidden`}>
          <div className={panelStyles.headerPanel__inner}>
            <Navigation />
            <div className={styles.mobileMenu__bottomContent}>
              {authenticated && (
                <Button className={styles.logoutButton} callBack={onLogout}>
                  Log out
                </Button>
              )}
              <p className={styles.support}>
                We are here for you <br />
                <a href="https://www.monotype.com/colophon-speak-sales">Contact Us</a>
              </p>
              <ul className={styles.mobileMenu__bottomMenu}>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.monotype.com/legal/terms-use"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.monotype.com/legal/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
